export default {
  data () {
    return {
      legendMeta: {
        risk: {
          title: 'Total Risk',
          category: 'risk-pof-cof'
        },
        'risk-total': {
          title: 'Total Risk',
          category: 'risk-pof-cof'
        },
        decision_variable: {
          title: 'Decision Variables',
          category: 'risk-pof-cof'
        },
        pof: {
          title: 'PoF',
          category: 'risk-pof-cof'
        },
        cof: {
          title: 'CoF',
          category: 'risk-pof-cof'
        },
        event: {
          title: 'Events',
          category: 'event'
        },
        inspection: {
          title: 'Events',
          category: 'inspection'
        },
        eventline: {
          title: 'Event lines',
          category: 'eventline'
        },
        cp: {
          title: 'Cathodic Protection',
          category: 'cp'
        },
        bathy: {
          title: 'Bathy Survey',
          category: 'bathy'
        },
        'bathy-survey': {
          title: 'Bathy Survey',
          category: 'bathy'
        },
        ili: {
          title: 'In-line Inspection',
          category: 'ili'
        },
        dcvg: {
          title: 'DCVG - % IR value',
          category: 'dcvg'
        },
        product: {
          title: 'Product',
          category: 'asset-property'
        },
        'asset-property-product': {
          title: 'Product',
          category: 'asset-property'
        },
        'asset-property-water-depth': {
          title: 'Water Depth',
          category: 'asset-property'
        }
      }
    }
  },
  methods: {
    getLegendMetaByKey (key) {
      return this.legendMeta[key] || { title: key.charAt(0).toUpperCase() + key.slice(1) }
    }
  }
}
