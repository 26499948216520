<template>
  <div class="mapbox-gl-control legends"
       :style="[dynamicOffsetStyle, posStyle]">
    <v-card v-if="activeLegends.length > 0"
            class="d-inline-flex flex-row-reverse align-end"
            color="rgba(0,0,0,0)"
            flat
            min-width="10rem">
      <v-card
        v-for="(legend, n) in activeLegends"
        :key="n"
        style="margin-right: 0.125rem"
        outlined
        tile
        class="overflow-y-auto"
        max-height="37.5vh"
        min-width="10rem"
      >
        <v-card-title class="text-h6 text-body-1 font-weight-bold pb-0 text-truncate">{{ legend.title || getLegendMeta(legend).title }}</v-card-title>

        <v-card-text class="pb-0">
          <span v-if="legend.subtitle">
            {{ legend.subtitle }}
          </span>

          <v-list dense>
            <v-list-item v-for="(item, i) in legend.items"
                         :key="i"
                         class="pa-0">
              <v-list-item-avatar tile
                                  size="20"
                                  :style="getChipStyle(item)" />

              <v-list-item-content>
                <v-list-item-title v-text="item.label"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-card>
  </div>
</template>

<script>
import positionStyleMixin from '@/utils/mixins/styling/positionStyle.mixin.js'
import offsetStyleMixin from '@/utils/mixins/styling/offsetStyle.mixin.js'
import legendMetaMixin from '@/utils/mixins/meta/legendMeta.mixin'
import { mapState } from 'vuex'

export default {
  name: 'pibot-mapbox-legends',
  mixins: [positionStyleMixin, offsetStyleMixin, legendMetaMixin],
  data () {
    return {
    }
  },
  computed: {
    ...mapState({
      activeLegends: state => state.legends.activeLegends,
      chartActive: state => state.mapbox.chartActive,
      chartHeight: state => state.mapbox.chartHeight
    }),
    dynamicOffsetStyle () {
      if (!this.chartActive) return this.offsetStyle

      let offsets = JSON.parse(JSON.stringify(this.offsets))
      offsets[1] = `calc(${offsets[1]} - ${this.chartHeight})`
      offsets = offsets.join(',')
      return { transform: `translate(${offsets})` }
    }
  },
  methods: {
    getLegendMeta ({ id, type }) {
      if (id) return this.getLegendMetaByKey(id)
      else if (type) return this.getLegendMetaByKey(type) // Backwards compatibility
      else return ''
    },
    getChipStyle (item) {
      return {
        'border-color': '#000',
        'border-width': '1px',
        'border-style': 'solid',
        borderRadius: '0.333333rem',
        background: item.color.length === 1 ? item.color[0] : `linear-gradient(90deg, ${item.color[0]} 50%, ${item.color[1]} 50%)`
      }
    }
  }
}
</script>
